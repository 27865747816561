
import { defineComponent, PropType, watch, ref, onMounted } from 'vue'
import TableView from '@/components/Table/TableView.vue'
import { eventsColumns } from './Events.mocks'
import { CourseTag, EventModel } from './Events.interface'
import constants from '@/utils/Constants.json'
import { Coach } from '@/types/Coach'
import { createDateFormatter, getProducts } from './Events.utils'
import { PubSubFactory, PubSubs } from '@/mid-layer/Factories/PubSubFactory'
import { ApplyEventSearchCriteria, EventListUpdated } from '@/types/Topic'
import { injectEventsStore } from './Events.store'
import locales from './Events.locales.en.json'
import DeleteConfirmationModal from '@/components/Shared/DeleteConfirmationModal.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'

interface ExtraKeys {
  productsNames: string;
  jurisdictionsNames: string;
  coachName: string;
  dayPeriod: string;
  timePeriod: string;
}
export default defineComponent({
  components: {
    TableView,
    DeleteConfirmationModal
  },
  props: {
    data: {
      type: Array as PropType<(ExtraKeys & EventModel)[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    coaches: {
      type: Array as PropType<Coach[]>,
      required: true
    },
    searchText: {
      type: String,
      default: ''
    },
    courseTags: {
      type: Object as PropType<CourseTag[]>,
      default: () => null
    }
  },
  emits: ['event-selected', 'events-filtered'],
  setup(props, { emit }) {
    const { showErrorToast } = useToast()
    const events = ref<(ExtraKeys & EventModel)[]>([])
    const filteredEvents = ref<(ExtraKeys & EventModel)[]>([])

    const pubSub = PubSubFactory.GetPubSub(PubSubs.PubSubJS)
    const visibleDeleteModal = ref<boolean>(false)
    const disableAcceptDelete = ref<boolean>(false)
    let eventToDelete = ''
    const store = injectEventsStore()

    onMounted(() => {
      pubSub.Subscribe(ApplyEventSearchCriteria, () => {
        filterEvents(props.searchText)
      })
      formatEvents()
    })

    watch([() => props.data, () => props.coaches], () => {
      formatEvents()
    })

    watch(
      () => props.searchText,
      () => {
        filterEvents(props.searchText)
      }
    )

    function formatEvents() {
      const result = props.data.map((event: ExtraKeys & EventModel) => {
        return {
          ...event,
          productsNames: getProducts(event),
          capacity: event.attendees.length + '/' + event.maxAttendees,
          jurisdictionsNames: getJurisdictions(event),
          coachName:
            props.coaches.find((coach: Coach) => coach.id === event.coachId)
              ?.name || '',
          dayPeriod: getDayPeriod(event),
          timePeriod: getTimePeriod(event)
        }
      })

      events.value = result
      filteredEvents.value = result
    }

    function getJurisdictions(eventInfo: EventModel): string {
      if (!eventInfo.jurisdictions) return ''
      return constants.Jurisdictions.filter((jurisdiction) =>
        eventInfo.jurisdictions.some(
          (eventJurisdiction) => eventJurisdiction === jurisdiction.value
        )
      )
        .map((jurisdiction) => jurisdiction.label)
        .join(', ')
    }

    function getDayPeriod(eventInfo: EventModel) {
      return createDateFormatter(eventInfo).formatDateRange()
    }

    function getTimePeriod(eventInfo: EventModel) {
      return createDateFormatter(eventInfo).formatTimeRange()
    }

    async function handleDeleteClick(event: Event, record: EventModel) {
      event.stopPropagation()
      if (record.attendees.length > 0) {
        showErrorToast({ message: "Can't delete event with attendees" })
      }
      else {
        visibleDeleteModal.value = true
        eventToDelete = record?.id ?? ''
      }
    }

    async function onAcceptDelete() {
      try {
        disableAcceptDelete.value = true
        await store.actions.deleteEvent(eventToDelete)
        pubSub.Publish(EventListUpdated)
        pubSub.Publish(ApplyEventSearchCriteria)
        visibleDeleteModal.value = false
        disableAcceptDelete.value = false
        eventToDelete = ''
      } catch (error) {
        disableAcceptDelete.value = false
      }
    }

    function onDeclineDelete() {
      visibleDeleteModal.value = false
      eventToDelete = ''
    }

    function handleRowClick(rowData: EventModel) {
      emit(
        'event-selected',
        events.value.find(
          (event: ExtraKeys & EventModel) => event.id === rowData.id
        )
      )
    }

    function filterEvents(criteria: string) {
      const filterByDateTime = events.value.filter(
        (event: ExtraKeys & EventModel) => {
          const dateSearchString = event.dayPeriod + ' ' + event.timePeriod
          return dateSearchString.toUpperCase().indexOf(criteria.toUpperCase()) > -1
        }
      )

      const productsString = (products: string[]) => {
        return products.map((product: string) => {
          const found = constants.Products.find((x) => x.value === product)
          return found ? found.label : ''
        }).join(', ')
      }

      const filterByProduct = events.value.filter(
          (event: ExtraKeys & EventModel) =>
          productsString(event.products).toUpperCase().includes(criteria.toUpperCase()))

      const filterByJurisdiction = events.value.filter(
        (event: ExtraKeys & EventModel) => {
          if (event.jurisdictions) {
            return event.jurisdictions.join(', ').toUpperCase().includes(criteria.toUpperCase())
          }
        }
      )

      const filterByCoach = events.value.filter(
        (event: ExtraKeys & EventModel) =>
          event.coachName.toUpperCase().indexOf(criteria.toUpperCase()) > -1
      )

      const filterByAttendees = events.value.filter(
        (event: ExtraKeys & EventModel) =>
          event.attendees.some((value) =>
            value.toUpperCase().includes(criteria.toUpperCase())
          )
      )

      const filterByCapacity = events.value.filter(
        (event: ExtraKeys & EventModel) =>
          (event.attendees.length + '/' + event.maxAttendees).includes(criteria.toUpperCase())
      )

      const filterByHidden = events.value.filter(
        (event: ExtraKeys & EventModel) =>
          (event.isHidden ? 'YES' : 'NO').includes(criteria.toUpperCase())
      )

      const filterByTitle = events.value.filter(
        (event: ExtraKeys & EventModel) =>
          (event.title ?? '').toUpperCase().includes(criteria.toUpperCase())
      )

      const allResults = [
        ...filterByDateTime,
        ...filterByProduct,
        ...filterByJurisdiction,
        ...filterByCoach,
        ...filterByAttendees,
        ...filterByCapacity,
        ...filterByHidden,
        ...filterByTitle
      ]

      filteredEvents.value = events.value.filter(
        (event) => allResults.includes(event)
      )

      emit('events-filtered', filteredEvents.value.length)
    }

    const isHidden = function (rowData: EventModel) {
      return rowData.isHidden ? 'Yes' : 'No'
    }

    const hasTags = function (event:EventModel){
      return (event.tags?.length ?? 0) > 0
    }

    function getTagColor(tagAbbr: string){
      const colorString = 'color: ' + props.courseTags?.find(tag => tag.abbreviation == tagAbbr)?.color
      return colorString
    }

    return {
      eventsColumns,
      events,
      filteredEvents,
      handleRowClick,
      handleDeleteClick,
      visibleDeleteModal,
      onAcceptDelete,
      onDeclineDelete,
      locales,
      disableAcceptDelete,
      isHidden,
      hasTags,
      getTagColor
    }
  }
})
